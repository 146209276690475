/* Footer.css */

.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #f2f2f2;
  }
  
  .logo img {
    height: 60px;
  }
  
  .text p {
    font-size: 25px;
    color: #666666;
  }

  .p2 {
    font-size:15px;
  }
  