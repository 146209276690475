.card {
    background: linear-gradient(to right, #1bb0dd, #026abe); /* Gradient from orange to dark orange */
    border-radius: 24px;
    padding: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
    transition: transform 0.3s; /* Add transition property for smooth scaling */
  }
  
  .card:hover {
    transform: scale(1.1); /* Scale the card on hover */
  }
  
  .card-content {
    font-size: 24px;
    color: #000000; /* Black color for text */
  }
  