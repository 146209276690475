/* Default styles */

.about-section {
  background-image: linear-gradient(#ffffff, #A2D1FA);
}

  
  .about-content {
    display: flex;
    justify-content: space-between;
  }
  
  .about-left {
    width: 40%;
    margin-left: 160px;
    margin-top: 5px;
  }
  
  .about-right {
    width: 60%;
    margin-right: 150px;
    margin-top: 110px;
    margin-bottom: 50px;
  }

  .about-right img {
    width: 60%;
    justify-content: space-between;
    display: block;
margin-left: auto;
margin-right: auto;
  }
  
  .about-left img {
    width: 700px; /* Adjust the width of the image */
    height: 700px; /* Adjust the height of the image */
    margin-right: 10px; /* Add spacing between the image and text */
    vertical-align: middle; /* Align the image vertically with the text */
    transition: transform 0.3s; /* Add transition property for smooth scaling */
  }
  
  .about-left img:hover {
    transform: scale(1.1); /* Scale the image on hover */
  }
  
  
  .about-right p {
    font-size: 24px;
    color: #000000;
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column; /* Stack the content vertically on mobile */
      align-items: center; /* Center align the content on mobile */
    }
  
    .about-left {
      width: 80%; /* Adjust the width to occupy more space on mobile */
      margin: 0; /* Remove margins */
      margin-top: 50px; /* Adjust the top margin on mobile */
      text-align: center; /* Center align the text on mobile */
    }
  
    .about-right {
      width: 80%; /* Adjust the width to occupy more space on mobile */
      margin: 0; /* Remove margins */
      margin-top: 30px; /* Adjust the top margin on mobile */
      text-align: center; /* Center align the text on mobile */
    }
  

  
    .about-left img {
      width: 400px; /* Adjust the width of the image */
      height: 400px; /* Adjust the height of the image */
      margin-right: 8px; /* Add spacing between the image and text on mobile */
    }
  
    .about-right p {
      font-size: 18px; /* Decrease the font size on mobile */
      font-weight: bold; /* Make the text bold */
      text-align: center; /* Center the text */
    }
    
    
  }
  