.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0); /* Set background color to transparent */
    color: black; /* Set text color to black */
    padding: 0px;
    z-index: 999;
  }
  
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.9); /* Add black background with low opacity */
    position: fixed;
    top: 0;
    left: 0; /* Set left to 0 instead of using margin */
    transform: translateX(0); /* Remove the transform property */
    width: 100%; /* Set width to 100% to span the entire viewport */
  }
  
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    margin-right: 150px; /* Add margin to the right */
  }
  
  .menu {
    list-style-type: none;
    display: flex;
    margin-right: 20px;
  }
  
  .menu li {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    font-size: 20px; /* Set the font size to a larger value */
    color: white; /* Set text color to white */
    text-shadow: 2px 2px #080808;
  }
  
  .menu li:hover {
    transform: translateY(-2px); /* Apply vertical translation on hover */
  }
  
  .buy-button {
    background-color: orange; /* Set button background color to orange */
    color: white; /* Set button text color to white */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px; /* Add rounded corners */
    transition: transform 0.3s; /* Add transition property for smooth effect */
    font-size: 18px; /* Set the font size to a larger value */
  }
  
  .buy-button:hover {
    background-color: darkorange; /* Set button background color to dark orange on hover */
    transform: scale(1.1); /* Scale the button on hover */
  }
  
  .logo {
    width: 300px;
    height: 70px;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    margin: 0 100px; /* Add margin to the right and left */
  }
  
  .logo:hover {
    transform: scale(1.1); /* Scale the logo on hover */
  }
  
  /* Media Query for Mobile Devices */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0); /* Set background color to transparent */
    color: black; /* Set text color to black */
    padding: 0px;
    z-index: 999;
  }
  
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.9); /* Add black background with low opacity */
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw; /* Set width to 100vw to span the entire viewport */
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
    margin-right: 150px; /* Add margin to the right */
  }
  
  .menu {
    list-style-type: none;
    display: flex;
    margin-right: 20px;
  }
  
  .menu li {
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    font-size: 20px; /* Set the font size to a larger value */
    color: white; /* Set text color to white */
    text-decoration: none;
  }
  .menu li a {
    text-decoration: none; /* Remove hyperlink underline */
    color: inherit; /* Inherit parent's color */
  }
  
  .menu li:hover {
    transform: translateY(-2px); /* Apply vertical translation on hover */
  }
  
  .buy-button {
    background-color: rgb(255, 255, 255); /* Set button background color to orange */
    color: inherit; /* Set button text color to white */
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 10px; /* Add rounded corners */
    transition: transform 0.3s; /* Add transition property for smooth effect */
    font-size: 18px; /* Set the font size to a larger value */
  }

  .buy-link {
    text-decoration: none; /* Remove hyperlink underline */
    color: inherit; /* Inherit parent's color */
  }
  
  .buy-button:hover {
    background-color: rgb(214, 214, 214); /* Set button background color to dark orange on hover */
    transform: scale(1.1); /* Scale the button on hover */
  }
  
  .logo {
    width: 300px;
    height: 70px;
    transition: transform 0.3s; /* Add transition property for smooth effect */
    margin: 0 100px; /* Add margin to the right and left */
  }
  
  .logo:hover {
    transform: scale(1.1); /* Scale the logo on hover */
  }
  
  /* Media Query for Mobile Devices */
  @media (max-width: 768px) {
    .navbar {
      flex-direction: column;
      justify-content: center;
      margin: 0;
      padding: 12px 1px;
      margin-top: 2w0px;
    }
    
    .navbar-left {
        margin: 0;
        width: 100%;
        margin-right: 76px;
      }
    .navbar-right {
      margin: 0;
      width: 100%;
      margin-left: 20px;
    }
    
    .navbar-right {
      margin-top: 10px;
    }
    
    .menu {
      margin: 0;
    }

    
  }

  