/* Default styles */

.hero {
    position: relative;
    width: 100%;
    height: 100vh;
  }
  
  .hero-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
  }
  
  .header {
    font-size: 80px;
    color: white;
  }
  
  .banner {
    width: 800px;
    height: auto;
    margin-top: 2px;
  }
  
  .icon-container {
    margin-top: 40px;
  }
  
  .icon {
    width: 80px;
    height: auto;
    margin: 0 10px;
    fill: white;
  }
  
  .icon path {
    fill: white;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Mobile responsiveness */
  
  @media (max-width: 768px) {
    .header {
      font-size: 40px; /* Decrease the font size for mobile */
    }
  
    .banner {
      width: 100%; /* Adjust the width of the banner to fit the screen */
      margin-top: 20px; /* Adjust the margin for the banner */
      margin-right: 10px;
    }
  
    .icon-container {
      margin-top: 20px; /* Adjust the margin for the icon container */
    }
  
    .icon {
      width: 40px; /* Decrease the width of the SVG icons */
      margin: 0 5px; /* Adjust the margin around the SVG icons */
    }
  }
  
  .orange-button {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 10px 40px; /* Update the padding values to adjust the x-padding */
    background-color: orange;
    margin-top: 20px;
    color: white;
    border-radius: 10px;
    font-size: 40px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  
  
  .orange-button:hover {
    background-color: darkorange;
  }
  