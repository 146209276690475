/* Tokenomic.css */

body {
    margin: 0;
    padding: 0;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200vh;
    background-color: #f2f2f2;
    background-image: url('https://i.imgur.com/oEMn4CN.png');
    background-size: cover; /* Fit the background image in the browser */
    background-repeat: no-repeat;
    background-position: center;
  }
  
  
  
  .content {
    font-size: 24px;
    font-weight: bold;
  }
  